import { Component, OnInit } from '@angular/core';
import { CourtlistService } from '../../../courtlist.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-court-wise',
  templateUrl: './court-wise.component.html',
  styleUrls: ['./court-wise.component.css']
})
export class CourtWiseComponent implements OnInit {

  selectedCourtName: string = '';

  //event handler for the select element's change event
  selectChangeHandler (event: Event) {
    let selectedOptions = event.target['options'];
    let selectedIndex = selectedOptions.selectedIndex;
    this.selectedCourtName = selectedOptions[selectedIndex].text;
  }

  courtlistdata:any = [];
  courtwisedata:any = [];
  courtWiseJusticeNamedata:any = [];
  //searchDate2: any;
  searchDate2: Date = new Date();
  courtid: any;
  courtName: any;

  is_draft: number;
  
  errorMessage: string;
  isLoading: boolean = true;
  ClickSearchMsg:boolean = true;
  detailsdata:boolean = false;
  JusticeName:boolean = false;

  errorMsg: boolean = false;
  errorMsg2: boolean = false;

  isLoadingImage: boolean = false;

  constructor(
      public rest:CourtlistService, 
      private route: ActivatedRoute, 
      private router: Router
  ) { }

  ngOnInit() {
    //this.getCasetoday();

    this.getCourtlist();

    //let lulu = document.getElementById('asdasd') as HTMLElement;

    //lulu.click();

  }

  
  getCourtlist() {
    //console.log("dd");
    /*this.courtlistdata = [];
    this.castoday$ = this.rest.getCasetoday().subscribe((data: {}) => {
      console.log(data);
      this.courtlistdata = data;
    });*/

    let postData = {
      searchDate2: this.searchDate2,
    }
    //console.log(postData);

    this.rest
        .getCourtlist(postData)
        .subscribe(
            courtlistdata => {
                this.courtlistdata = courtlistdata
                this.isLoading = false
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
            }
        );
  }  


  onChange(value){
    //console.log(value);
    this.searchDate2=value;
    this.getCourtlist();
  }


  getCourtwise() {


    if(this.courtid === undefined || this.courtid == ""){
      this.errorMsg2 = true;
      this.isLoading = true;
      return;
    } else if (this.searchDate2 === undefined || this.searchDate2 == null){
      this.errorMsg2 = false;
      this.errorMsg = true;
      this.isLoading = true;
      return;
    }

    this.errorMsg2 = false;
    this.errorMsg = false;


    let postData = {
      searchDate2: this.searchDate2,
      courtid: this.courtid,
    }
    //console.log(postData);

    this.isLoadingImage = true
    this.detailsdata = false

    if(postData.searchDate2.getFullYear() == (new Date()).getFullYear())
    {
        this.rest
                .getDraftStatus(postData)
                .subscribe(
                  is_draft => {
                        this.is_draft = is_draft
                    }
                );   
    } 
 

    this.rest
        .getCourtwise(postData)
        .subscribe(
          groupPageArray => {
            //console.log(groupPageArray);
              this.courtwisedata = groupPageArray
              //console.log(this.courtwisedata);
              this.isLoading = false
              this.ClickSearchMsg = false
              this.detailsdata = true
              this.isLoadingImage = false

            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
                this.isLoadingImage = false
            }
        );
  }

  getCourtWiseJusticeName() {
    let postData = {
      searchDate2: this.searchDate2,
      courtid: this.courtid,
    }
    //console.log(postData);

    this.rest
        .getCourtWiseJusticeName(postData)
        .subscribe(
          courtWiseJusticeNamedata => {
                this.courtWiseJusticeNamedata = courtWiseJusticeNamedata
                this.isLoading = false 
                this.JusticeName=true;

            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
            }
        );
  }


}
