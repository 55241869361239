import { Component, OnInit, Renderer2 } from '@angular/core';
import { LawyerinfoService } from '../../lawyerinfo.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-scroll-news',
  templateUrl: './scroll-news.component.html',
  styleUrls: ['./scroll-news.component.css']
})

export class ScrollNewsComponent implements OnInit {

  scrollnewsdata:any = [];
  //newsID: any;
  errorMessage: string;

  //yt = '<iframe class="w-100" src="https://www.youtube.com/embed/KS76EghdCcY?rel=0&amp;controls=0" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>';
  public id: string;

  constructor(
    public rest:LawyerinfoService, 
    private route: ActivatedRoute, 
    private router: Router,
    private renderer: Renderer2
) { }


  ngOnInit() {

    this.gescrollnewsdata();

    this.id = this.route.snapshot.paramMap.get('id');

    //console.log(this.id);

    //this.param1 = this.route.snapshot.paramMap.get('param1');
    //this.id = this.route.snapshot.paramMap.get('id');
    //console.log(this.route.snapshot.paramMap.get('id'));

   // window.location.reload();

  }

  gescrollnewsdata() {
    let postData = {
      id: this.route.snapshot.paramMap.get('id'),
    }
    //console.log(postData);
   // window.location.reload();

   //location.reload(true);
   //window.location.reload(true);

    this.rest
        .gescrollnewsdata(postData)
        .subscribe(
          scrollnewsdata => {
           //console.log(scrollnewsdata);
              this.scrollnewsdata = scrollnewsdata
            },
            error => {
                this.errorMessage = <any>error;
            }
        );
  }

}
