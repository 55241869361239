import { Component, OnInit } from '@angular/core';
import {formatDate} from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { LawyerinfoService } from '../lawyerinfo.service';

@Component({
  selector: 'app-leftsidebar',
  templateUrl: './leftsidebar.component.html',
  styleUrls: ['./leftsidebar.component.css']
})
export class LeftsidebarComponent implements OnInit {

  currentDate: Date = new Date();

  errorMessage: string;

  todayonlinevisitor:any = [];
  todaytotalvisitor:any = [];

  constructor(
    public rest:LawyerinfoService, 
) { }
  
  ngOnInit() {
    this.gettodayonlinevisitor();
    
    this.gettodaytotalvisitor();
  }

  gettodayonlinevisitor() {
    let postData = {
    }
    //console.log(postData);

    this.rest
        .gettodayonlinevisitor(postData)
        .subscribe(
          todayonlinevisitor => {
              this.todayonlinevisitor = todayonlinevisitor
            },
            error => {
                this.errorMessage = <any>error
            }
        );
  }

  gettodaytotalvisitor() {
    let postData = {
    }
    //console.log(postData);

    this.rest
        .gettodaytotalvisitor(postData)
        .subscribe(
          todaytotalvisitor => {
              this.todaytotalvisitor = todaytotalvisitor
            },
            error => {
                this.errorMessage = <any>error
            }
        );
  }

}
