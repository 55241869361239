import { Component, OnInit } from '@angular/core';
import { LawyerinfoService } from '../../lawyerinfo.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { SimpleModalService } from 'ngx-simple-modal';
import { AlertComponent } from 'src/app/alert/alert.component';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

  notificationdata:any = [];
  notificationmodal:any = [];
  current_laywerCode: string;

  aid: string;
  division: string;


  errorMessage: string;
  isLoading: boolean = true;
  isLoadingImage: boolean = true;



showModal: boolean;
content: string;
title: string;

  constructor(
      public rest:LawyerinfoService, 
      private route: ActivatedRoute, 
      private router: Router,
      public app:AppComponent,
      private SimpleModalService: SimpleModalService
  ) { 

      if (!this.app.loggedIn()) {
        //this.location.replaceState('/');
        this.router.navigate(['../']);
      }
    }




  ngOnInit() {
    this.current_laywerCode = this.app.getLawyerCode();
    this.getnotificationlist();
  }

  getnotificationlist() {
    let postData = {
      current_laywerCode:  this.current_laywerCode,
    }
    //console.log(postData);

    this.rest
        .getnotificationlist(postData)
        .subscribe(
          notificationdata => {
           // console.log(courtwisedata);
              this.notificationdata = notificationdata
              this.isLoading = false
              this.isLoadingImage = false
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
            }
        );
  }


    showNotification(aid,division) {
      let postData = {
        aid: aid,
        division: division,
      }

    this.rest
        .showNotification(postData)
        .subscribe(
          notificationmodal => {
            //console.log(advocatemodal[0].lawyer_name);
                this.notificationmodal = notificationmodal
                //console.log(this.notificationmodal);
                this.isLoading = false;
                this.showModal = true; // Show-Hide Modal Check
                //this.content = "This is content!!"; // Dynamic Data
                this.title = "YOUR NOTIFICATION DETAILS";
                //this.SimpleModalService.addModal(AlertComponent, {title: 'LAWYER INFORMATION', message: 'Code No : ' + notificationmodal[0].lawyer_id + "\n" + 'Name : ' + notificationmodal[0].lawyer_name + "\n\r" + 'Address : ' + notificationmodal[0].address + "\n\r" + 'Phone No : ' + notificationmodal[0].phone  });
               // this.SimpleModalService.addModal(AlertComponent, {title: 'YOUR NOTIFICATION DETAILS', 
                //message: '<table><thead><tr><th>Case Name</th><th>Case Type</th><th>Case No.</th><th>P/R</th><th>Entry Date</th></tr><thead><tbody><tr *ngFor="let value of notificationmodal"><td>{{ value.lawyer_id }}</td><td></td><td></td><td></td><td></td></tr></tbody></table>'});
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
            }
        );
  }

  // show()
  // {
  //   this.showModal = true; // Show-Hide Modal Check
  //   this.content = "This is content!!"; // Dynamic Data
  //   this.title = "YOUR NOTIFICATION DETAILS";    // Dynamic Data
  // }
  //Bootstrap Modal Close event
  hide()
  {
    this.showModal = false;
  }

}
