import { Component, OnInit } from '@angular/core';
import { AppellatedivisionService } from '../../../appellatedivision.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-ad-page-wise',
  templateUrl: './ad-page-wise.component.html',
  styleUrls: ['./ad-page-wise.component.css']
})
export class AdPageWiseComponent implements OnInit {

  selectedCourtName: string = '';

  //event handler for the select element's change event
  selectChangeHandler (event: Event) {
    let selectedOptions = event.target['options'];
    let selectedIndex = selectedOptions.selectedIndex;
    this.selectedCourtName = selectedOptions[selectedIndex].text;
  }

  pagenodata:any = [];
  pagewisedata:any = [];
  //searchDate2: any;
  searchDate2: Date = new Date();
  pagenumber: any;
  courtName: any;

  is_draft: number;
  
  errorMessage: string;
  isLoading: boolean = true;
  ClickSearchMsg:boolean = true;
  detailsdata:boolean = false;
  JusticeName:boolean = false;

  errorMsg: boolean = false;
  errorMsg2: boolean = false;

  isLoadingImage: boolean = false;

  constructor(
      public rest:AppellatedivisionService, 
      private route: ActivatedRoute, 
      private router: Router
  ) { }

  ngOnInit() {
    this.getPagelist();
  }

  
  getPagelist() {
    let postData = {
      searchDate2: this.searchDate2,
    }
    //console.log(postData);

    this.rest
        .getPagelist(postData)
        .subscribe(
            pagenodata => {
                this.pagenodata = pagenodata
                this.isLoading = false
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
            }
        );
  }  

  onChange(value){
    //console.log(value);
    this.searchDate2=value;
    this.getPagelist();
  }


  getPagewise() {

    if(this.pagenumber === undefined || this.pagenumber == ""){
      this.errorMsg2 = true;
      this.isLoading = true;
      return;
    } else if (this.searchDate2 === undefined || this.searchDate2 == null){
      this.errorMsg2 = false;
      this.errorMsg = true;
      this.isLoading = true;
      return;
    }

    this.errorMsg2 = false;
    this.errorMsg = false;


    let postData = {
      searchDate2: this.searchDate2,
      pagenumber: this.pagenumber,
    }
    //console.log(postData);

    this.isLoadingImage = true
    this.detailsdata = false

    if(postData.searchDate2.getFullYear() == (new Date()).getFullYear())
    {
          this.rest
                  .getDraftStatusAd(postData)
                  .subscribe(
                    is_draft => {
                          this.is_draft = is_draft
                      }
                  );   
    }

    this.rest
        .getPagewise(postData)
        .subscribe(
          groupPageArray => {
            //console.log(groupPageArray);
              this.pagewisedata = groupPageArray;
              //console.log(this.pagewisedata);
              this.isLoading = false
              this.ClickSearchMsg = false
              this.detailsdata = true
              this.isLoadingImage = false

            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
                this.isLoadingImage = false
            }
        );
  }

}